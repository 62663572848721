import actions from './actions';

const { setDate, setTime, resetDate } = actions;

const onSetDate = selected => {
  return async dispatch => {
    dispatch(setDate(selected));
  };
};

const onSetTime = selected => {
  return async dispatch => {
    dispatch(setTime(selected));
  };
};

const onResetDate = () => {
  return async dispatch => {
    dispatch(resetDate());
  };
};

export { onSetDate, onSetTime, onResetDate };
