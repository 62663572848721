import { message } from 'antd';
import actions from './actions';

const initState = {
  idealteamplayers: [],
  isLoading: false,

  recentSubmittalsLoading: false,
  recentSubmittialsData: [],
};

const {
  IDEALTEAMPLAYER_BEGIN,
  IDEALTEAMPLAYER_ADD_SUCCESS,
  IDEALTEAMPLAYER_ERR,
  READ_RECENT_SUBMITTALS_BEGIN,
  READ_RECENT_SUBMITTALS_SUCCESS,
} = actions;

const IdealTeamPlayerReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case IDEALTEAMPLAYER_BEGIN:
      return {
        ...state,
        isLoading: true,
      };
    case IDEALTEAMPLAYER_ADD_SUCCESS:
      message.success('Form submitted successfully');
      data.form.setFieldsValue({
        player_name: '',
        nominating_reason: '',
      });
      return {
        ...state,
        isLoading: false,
      };
    case IDEALTEAMPLAYER_ERR:
      message.error('Error occured');

      return {
        ...state,
        isLoading: true,
      };

    case READ_RECENT_SUBMITTALS_BEGIN:
      return {
        ...state,
        recentSubmittalsLoading: true,
      };

    case READ_RECENT_SUBMITTALS_SUCCESS:
      return {
        ...state,
        recentSubmittalsLoading: false,
        recentSubmittialsData: data,
      };
    default:
      return state;
  }
};
export default IdealTeamPlayerReducer;
