const endPoints = {
  auth: {
    login: '/signin',
    signup: '/signup',
    signout: '/signout',
  },
  changePassApi: '/change-password',
  users: '/users',
  userLogs: '/user-logs',
  profile: '/profile',
  editUser: '/edit-user',
  userManager: '/user-manager',
  userManagerLog: '/user-manager-log',
  voipReport: '/voip-report',
  voipPieChartOverview: '/voip-report/inbound_outbound',
  voipBarChartOverview: '/voip-report/inbound_outbound_entire',
  voipStatsPieChartOverview: '/voip-report/statistics',
  textUsUserEngagement: '/textus/user_engagement',
  textUsOneUserEngagement: '/textus/one_user_engagement',
  textUsMessageDeliverability: '/textus/message_deliverability',
  textUsAnalyticsOverview: '/textus/analytics',
  textUsCompanyWide: '/textus/company-wide',
  placementsData: '/placements',
  placementsCorporateUsers: '/placements/get_corporate_users',
  placementsPostSalespersonDate: '/placements/post_salesperson_dateend',
  placementsPostRecruiter: '/placements/post_recruiter',
  placementsUpdatePushed: '/placements/pushed',
  syntheticdata: '/synthetic',
  rentalHistory: '/rental_history',
  asanaSettingsLogin: '/oauth/asana/login',
  asanaPortfolio: '/asana-portfolios',
  salesProfile: {
    saleUsers: 'sales-profile/sales-users',
    accountSummary: 'sales-profile/account-summary',
    monthlySummary: 'sales-profile/monthly-summary',
    weeklySummary: 'sales-profile/weekly-summary',
    weeklySalesTarget: 'sales-profile/weekly-sales-target',
    textus: 'sales-profile/textus',
    voip: 'sales-profile/voip',
    contractorsEnding: 'sales-profile/contractors-ending',
    spreadHistory: 'sales-profile/spread-history',
    companyLeaderboard: 'sales-profile/company-leaderboard',
    salesPerformance: 'sales-profile/sales-performance',
    salesUserCalender: 'sales-profile/user-calender',
    placementStarting: 'sales-profile/placements-starting',
    placementDetails: 'sales-profile/placements-details',
  },
  salesManagement: {
    getSalesUsers: 'sales-management',
  },
  salesTextus: {
    textUsUserEngagement: '/sales-textus/user_engagement',
    textUsOneUserEngagement: '/sales-textus/one_user_engagement',
    textUsMessageDeliverability: '/sales-textus/message_deliverability',
    textUsAnalyticsOverview: '/sales-textus/analytics',
    textUsCompanyWide: '/sales-textus/company-wide',
  },
  recruiterProfile: {
    recruiterUsers: 'recruiter-profile/recruiter-users',
    accountSummary: 'recruiter-profile/account-summary',
    monthlySummary: 'recruiter-profile/monthly-summary',
    weeklySummary: 'recruiter-profile/weekly-summary',
    weeklySalesTarget: 'recruiter-profile/weekly-sales-target',
    textus: 'recruiter-profile/textus',
    voip: 'recruiter-profile/voip',
    contractorsEnding: 'recruiter-profile/contractors-ending',
    spreadHistory: 'recruiter-profile/spread-history',
    companyLeaderboard: 'recruiter-profile/company-leaderboard',
    recruiterPerformance: 'recruiter-profile/recruiter-performance',
    recruiterUserCalender: 'recruiter-profile/user-calender',
    placementStarting: 'recruiter-profile/placements-starting',
    placementDetails: 'recruiter-profile/placements-details',
  },
  recruiterManagement: {
    getRecruiters: 'recruiters-management',
  },
  recruiterAlignment: {
    recruiterAlignment: 'recruiters-alignment',
    recruiterAssignments: 'recruiters-assignments',
  },
  companyOverview: {
    overview: 'company-overview',
    companyLeaderboard: 'company-overview/leaderboard',
    companyCalendarHistory: 'company-overview/get-calendar-history',
    companyRevenue: 'company-overview/revenue',
    hiringRevenue: 'company-overview/hiring-revenue',
    topPerformers: 'company-overview/top-performers',
    performersDetails: 'company-overview/performers-details',
    spreadHistory: 'company-overview/spread-history',
    placementDetails: 'company-overview/placement-details',
    ytdDetails: 'company-overview/ytd-details',
    monthlyBreakdown: 'company-overview/monthly-breakdown',
    placementSources: 'company-overview/placement-sources',
    topClients: 'company-overview/top-clients',
  },
  IdealTeamPlayer: '/ideal-team-player',
  recentSubmittals: '/recent-submittals',
};

export { endPoints };
