const actions = {
  READ_MONTHLY_BREAKDOWN_BEGIN: 'READ_MONTHLY_BREAKDOWN_BEGIN',
  READ_MONTHLY_BREAKDOWN_SUCCESS: 'READ_MONTHLY_BREAKDOWN_SUCCESS',

  READ_PLACEMENT_SOURCES_BEGIN: 'READ_PLACEMENT_SOURCES_BEGIN',
  READ_PLACEMENT_SOURCES_SUCCESS: 'READ_PLACEMENT_SOURCES_SUCCESS',

  READ_TOP_CLIENTS_BEGIN: 'READ_TOP_CLIENTS_BEGIN',
  READ_TOP_CLIENTS_SUCCESS: 'READ_TOP_CLIENTS_SUCCESS',

  readMonthlyBreakdownBegin: () => {
    return {
      type: actions.READ_MONTHLY_BREAKDOWN_BEGIN,
    };
  },

  readMonthlyBreakdownSuccess: data => {
    return {
      type: actions.READ_MONTHLY_BREAKDOWN_SUCCESS,
      data,
    };
  },

  readPlacementSourceBegin: () => ({ type: actions.READ_PLACEMENT_SOURCES_BEGIN }),

  readPlacementSourceSuccess: data => ({
    type: actions.READ_PLACEMENT_SOURCES_SUCCESS,
    data,
  }),

  readTopClientsBegin: () => ({ type: actions.READ_TOP_CLIENTS_BEGIN }),
  readTopClientsSuccess: data => ({ type: actions.READ_TOP_CLIENTS_SUCCESS, data }),
};

export default actions;
