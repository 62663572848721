import React, { lazy, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { screens } from '../../config/screens';
import useRoutes from '../../hooks/useRoutes';

const UserSettings = lazy(() => import('../../container/profile/settings/UserSettings'));

const DashboardRoutes = () => {
  const { path } = useRouteMatch();
  const [appRoutes] = useRoutes();
  const history = useHistory();
  const landingValue = localStorage.getItem('landingValue');
  useEffect(() => {
    if (landingValue) {
      let pathURL = '';
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < screens.length; i++) {
        if (Array.isArray(screens[i].menuItems))
          pathURL = screens[i].menuItems.find(item => item.key === landingValue)?.path;
        else if (screens[i].key === landingValue) pathURL = screens[i].path;
        if (pathURL) {
          localStorage.setItem('landingValue', '');
          history.push(`${path}${pathURL}`);
          break;
        }
      }
    }
  }, [landingValue]);
  return (
    <Switch>
      {appRoutes.map(route => (
        <Route path={`${path}${route.path}`} component={route.component} exact={route.exact} key={route.key} />
      ))}
      <Route path={`${path}${'/user/settings'}`} component={UserSettings} exact={false} />
    </Switch>
  );
};

export default DashboardRoutes;
