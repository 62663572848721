import { Select, Spin, Table, Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import Heading from '../../../../components/heading/heading';
import { getYearsList } from '../../../../components/utilities/utilities';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { getPlacementDetails } from '../../../../redux/salesProfile/actionCreator';
import { getColumnSearchProps } from '../../../../utility/sales-recruiterUtils';
import { formatCurrenyValue } from '../../../../utility/utility';

const SalesPlacements = ({ email, username }) => {
  const { Option } = Select;
  const [selectedYear, setSelectedYear] = useState(new Date().getUTCFullYear());
  const { isMobile } = useWindowDimensions();

  const [date, setDate] = useState({
    startDate:
      moment
        .utc()
        .year(selectedYear)
        .startOf('year')
        .unix() * 1000,
    endDate:
      moment
        .utc()
        .year(selectedYear)
        .endOf('year')
        .unix() * 1000,
  });

  const [, setState] = useState({
    dateRange: 'week',
    searchText: '',
    searchedColumn: '',
  });

  const { startDate, endDate } = date;

  const dispatch = useDispatch();

  const { placementDetails, isLoading } = useSelector(state => {
    return {
      placementDetails: state.salesProfile.placementDetails,
      isLoading: state.salesProfile.placementDetailsLoading,
    };
  });

  useEffect(() => {
    dispatch(getPlacementDetails({ email, startDate, endDate }));
  }, [dispatch, email]);

  const onYearSelect = year => {
    setSelectedYear(year);

    const sDate =
      moment
        .utc()
        .year(year)
        .startOf('year')
        .unix() * 1000;

    const eDate =
      moment
        .utc()
        .year(year)
        .endOf('year')
        .unix() * 1000;

    setDate({ startDate: sDate, endDate: eDate });

    dispatch(getPlacementDetails({ email, startDate: sDate, endDate: eDate }));
  };

  const PLACEMENT_DETAILS_COLUMNS = [
    {
      title: 'Start Date',
      dataIndex: 'dateBegin',
      key: 'dateBegin',
      render: value => moment(+value).format('MM/DD/YYYY'),
      sorter: (a, b) => a.dateBegin - b.dateBegin,
    },
    {
      title: 'End Date',
      dataIndex: 'dateEnd',
      key: 'dateEnd',
      render: value => (value ? moment(+value).format('MM/DD/YYYY') : '-'),
      sorter: (a, b) => a.dateEnd - b.dateEnd,
    },
    {
      title: 'Client',
      dataIndex: 'clientContact_name',
      key: 'clientContact_name',
      sorter: (a, b) => a.clientName.localeCompare(b.clientName),
    },

    {
      title: 'Candidate',
      dataIndex: 'candidate_name',
      key: 'candidate_name',
      sorter: (a, b) => a.candidate_nameSorter.localeCompare(b.candidate_nameSorter),
    },

    {
      title: 'Job Title',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.jobTitle.localeCompare(b.jobTitle),
    },

    {
      title: 'Recruiter',
      dataIndex: 'recruiter',
      key: 'recruiter',
      sorter: (a, b) => a.recruiter.localeCompare(b.recruiter),
      ...getColumnSearchProps('recruiter', setState),
    },
    {
      title: 'Spread',
      dataIndex: 'spread_amount',
      key: 'spread_amount',
      sorter: (a, b) => a.spread - b.spread,
      render: item => (
        <p>
          <b>{item}</b>
        </p>
      ),
    },
  ];
  placementDetails.sort((a, b) => b.dateBegin - a.dateBegin);
  const getMappedData = () => {
    return (
      placementDetails &&
      placementDetails.map(item => {
        const isSalary = item.employmentType === 'Salary' || item.employmentType === 'salary';
        return {
          ...item,
          spread_amount: isSalary ? (
            <div>{item.spread_amount ? formatCurrenyValue(item.spread_amount) : '$0'}</div>
          ) : (
            <Tooltip title="based on 40 hour work week" placement="topLeft">
              <div>{item.spread_amount ? formatCurrenyValue(item.spread_amount) : '$0'}</div>
            </Tooltip>
          ),
          spread: item.spread_amount ? item.spread_amount : 0,
          clientContact_name: (
            <figcaption>
              <Heading className="user-name" as="h6">
                {item.clientContact_name}
              </Heading>
              <div className="user-designation"> {item.clientCorporation_name}</div>
            </figcaption>
          ),
          clientName: item.clientContact_name,
          title: (
            <figcaption>
              <Heading className="user-name" as="h6">
                {item.title}
              </Heading>
              <div className="user-designation"> {item.employmentType}</div>
            </figcaption>
          ),
          jobTitle: item.title,
          candidate_nameSorter: item.candidate_name,
          candidate_name: (
            <figcaption>
              <Heading className="user-name" as="h6">
                {item.candidate_name}
              </Heading>
              <div className="user-designation"> {item.employeeType}</div>
            </figcaption>
          ),
        };
      })
    );
  };

  const mappedDataForCSV = placementDetails.map(placement => ({
    date_begin: moment(+placement.dateBegin).format('MM/DD/YYYY'),
    date_End: placement.dateEnd ? moment(+placement.dateEnd).format('MM/DD/YYYY') : '-',
    client_Contact_name: placement.clientContact_name,
    client_corporation_name: placement.clientCorporation_name,
    candidate_name: placement.candidate_name,
    employee_type: placement.employeeType,
    job_type: placement.title,
    recruiter: placement.recruiter,
    spread_amount: `$${placement.spread_amount}`,
  }));

  return (
    <Cards
      title="Placements"
      isbutton={
        <div>
          <Button
            disabled={isLoading}
            type="ghost"
            style={{
              fontWeight: 'bold',
              height: '40px',
              marginRight: '10px',
              marginBottom: isMobile ? '10px' : 0,
              width: isMobile ? '100%' : 'auto',
            }}
          >
            <CSVLink filename={`Placement_Details_${username}_${selectedYear}.csv`} data={mappedDataForCSV}>
              Export To CSV
            </CSVLink>
          </Button>
          <Select defaultValue={selectedYear} onChange={onYearSelect} style={{ width: '200px' }}>
            {getYearsList().map(item => (
              <Option value={item}>{item}</Option>
            ))}
          </Select>
        </div>
      }
    >
      {isLoading ? (
        <Spin style={{ display: 'flex', justifyContent: 'center' }} />
      ) : (
        <Table
          columns={PLACEMENT_DETAILS_COLUMNS}
          scroll={{ x: true }}
          dataSource={getMappedData()}
          pagination={false}
        />
      )}
    </Cards>
  );
};

SalesPlacements.propTypes = {
  email: PropTypes.string,
  username: PropTypes.string,
};

export default SalesPlacements;
