export const role = {
  superAdmin: 'Super Admin',
  admin: 'Admin',
  manager: 'Manager',
  recruiterManager: 'Recruiter Manager',
  salesManager: 'Sales Manager',
  user: 'User',
  recruiter: 'Recruiter',
  salesperson: 'Salesperson',
};
