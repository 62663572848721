const screens = [
  {
    order: 1,
    value: 'Company Overview',
    key: 'company_overview',
    menuType: 'submenu',
    icon: 'circle',
    menuItems: [
      {
        path: `/`,
        key: 'company_profile',
        value: 'Company Profile',
        filePath: 'dashboard/CompanyOverview',
        menuType: 'menu',
        icon: 'circle',
        exact: true,
      },
      {
        path: `/monthly-breakdown`,
        key: 'monthly_breakdown',
        value: 'Monthly Breakdown',
        filePath: 'dashboard/overview/company/MonthlyBreakdown',
        menuType: 'menu',
        icon: 'circle',
        exact: true,
      },
      {
        path: '/company-calendar',
        key: 'company-calendar',
        value: 'Company Calendar',
        filePath: 'dashboard/CompanyCalendar',
        menuType: 'menu',
        icon: 'message-square',
        exact: true,
      },
      {
        path: '/voip-report',
        key: 'voip_report',
        value: 'VoIP Report',
        filePath: 'dashboard/VoIPReport',
        menuType: 'menu',
        icon: 'phone',
        exact: true,
      },
      {
        path: '/textus-report',
        key: 'textus_report',
        value: 'TextUs Report',
        filePath: 'dashboard/TextUsReport',
        menuType: 'menu',
        icon: 'message-square',
        exact: true,
      },
    ],
  },
  {
    order: 2,
    key: '306090_report',
    value: '30/60/90 Report',
    menuType: 'submenu',
    icon: 'message-square',
    menuItems: [
      {
        path: `/306090-report`,
        key: '306090_report_view',
        value: '30/60/90 Report',
        filePath: 'dashboard/PlacementsReport',
        menuType: 'menu',
        exact: true,
      },
      {
        path: `/306090-report/edit`,
        key: '306090_report_edit',
        value: '30/60/90 Report Edit',
        filePath: 'dashboard/PlacementsReportEdit',
        menuType: 'menu',
        exact: true,
      },
    ],
  },
  {
    order: 3,
    path: `/asana`,
    key: 'asana',
    value: 'Asana Manager',
    filePath: 'dashboard/Asana',
    menuType: 'menu',
    icon: 'circle',
    exact: true,
  },
  {
    order: 4,
    path: `/asana-portfolio`,
    key: 'asana_portfolio',
    value: 'Asana Portfolio',
    filePath: 'dashboard/AsanaPortfolio',
    menuType: 'menu',
    icon: 'circle',
    exact: true,
  },
  {
    order: 5,
    path: `/synthetic`,
    key: 'synthetic',
    value: 'Synthetic Data',
    filePath: 'dashboard/SyntheticData',
    menuType: 'menu',
    icon: 'database',
    exact: true,
  },
  {
    order: 6,
    path: `/settings`,
    key: 'profile',
    value: 'Profile',
    filePath: 'profile/settings/Settings',
    menuType: 'topMenu',
    icon: 'circle',
    exact: false,
  },
  {
    order: 7,
    key: 'sales',
    value: 'Sales',
    menuType: 'submenu',
    icon: 'circle',
    menuItems: [
      {
        path: `/sales-profile`,
        key: 'sales_profile',
        value: 'Sales Profile',
        filePath: 'dashboard/SalesProfile',
        menuType: 'menu',
        icon: 'circle',
        exact: true,
      },
      {
        path: `/sales-management`,
        key: 'sales_management',
        value: 'Sales Management',
        filePath: 'dashboard/SalesManagement',
        menuType: 'menu',
        icon: 'circle',
        exact: true,
      },
    ],
  },
  {
    order: 8,
    key: 'recruiter',
    value: 'Recruiter',
    menuType: 'submenu',
    icon: 'circle',
    menuItems: [
      {
        path: `/recruiter-profile`,
        key: 'recruiter_profile',
        value: 'Recruiter Profile',
        filePath: 'dashboard/RecruiterProfile',
        menuType: 'menu',
        exact: true,
      },
      {
        path: `/recruiter-management`,
        key: 'recruiter_management',
        value: 'Recruiter Management',
        filePath: 'dashboard/RecruiterManagement',
        menuType: 'menu',
        exact: true,
      },
      {
        path: `/recruiter-alignment`,
        key: 'recruiter_alignment',
        value: 'Recruiter Alignment',
        filePath: 'dashboard/RecruiterAlignment',
        menuType: 'menu',
        exact: true,
      },
    ],
  },
  {
    order: 9,
    path: `/candidate-recommender`,
    key: 'candidate_recommender',
    value: 'Candidate Recommender',
    filePath: 'dashboard/CandidateRecommender',
    menuType: 'menu',
    icon: 'database',
    exact: true,
  },
  {
    order: 10,
    path: `/ideal-team-player`,
    key: 'ideal_team_player',
    value: 'Ideal Team Player',
    filePath: 'dashboard/IdealTeamPlayer',
    menuType: 'menu',
    icon: 'target',
    exact: true,
  },
  {
    order: 9999,
    path: `/users`,
    key: 'users',
    value: 'Users Manager',
    filePath: 'dashboard/Users',
    menuType: 'adminMenu',
    icon: 'user',
    exact: true,
  },
];

export { screens };
