import actions from './actions';

const {
  READ_MONTHLY_BREAKDOWN_BEGIN,
  READ_MONTHLY_BREAKDOWN_SUCCESS,
  READ_PLACEMENT_SOURCES_BEGIN,
  READ_PLACEMENT_SOURCES_SUCCESS,
  READ_TOP_CLIENTS_BEGIN,
  READ_TOP_CLIENTS_SUCCESS,
} = actions;

const initState = {
  monthlyBreakdownLoading: false,
  monthlyBreakdownData: [],

  placementSourcesLoading: false,
  placementSourcesData: [],

  topClientsLoading: false,
  topClientsData: [],
};

const monthlyBreakdownReducer = (state = initState, action) => {
  const { type, data } = action;

  switch (type) {
    case READ_MONTHLY_BREAKDOWN_BEGIN:
      return {
        ...state,
        monthlyBreakdownLoading: true,
      };
    case READ_MONTHLY_BREAKDOWN_SUCCESS:
      return {
        ...state,
        monthlyBreakdownData: data,
        monthlyBreakdownLoading: false,
      };
    case READ_PLACEMENT_SOURCES_BEGIN:
      return {
        ...state,
        placementSourcesLoading: true,
      };
    case READ_PLACEMENT_SOURCES_SUCCESS:
      return {
        ...state,
        placementSourcesData: data,
        placementSourcesLoading: false,
      };
    case READ_TOP_CLIENTS_BEGIN:
      return {
        ...state,
        topClientsLoading: true,
      };
    case READ_TOP_CLIENTS_SUCCESS:
      return {
        ...state,
        topClientsData: data,
        topClientsLoading: false,
      };
    default:
      return state;
  }
};

export default monthlyBreakdownReducer;
