const actions = {
  IDEALTEAMPLAYER_BEGIN: 'IDEALTEAMPLAYER_BEGIN',
  IDEALTEAMPLAYER_ADD_SUCCESS: 'IDEALTEAMPLAYER_ADD_SUCCESS',
  IDEALTEAMPLAYER_ERR: 'IDEALTEAMPLAYER_ERR',

  READ_RECENT_SUBMITTALS_BEGIN: 'READ_RECENT_SUBMITTALS_BEGIN',
  READ_RECENT_SUBMITTALS_SUCCESS: 'READ_RECENT_SUBMITTALS_SUCCESS',

  idealteamplayerBegin: () => {
    return {
      type: actions.IDEALTEAMPLAYER_BEGIN,
    };
  },

  idealteamplayerSuccess: data => {
    return {
      type: actions.IDEALTEAMPLAYER_ADD_SUCCESS,
      data,
    };
  },

  idealteamplayerErr: err => {
    return {
      type: actions.IDEALTEAMPLAYER_ERR,
      err,
    };
  },

  recentSubmittalsBegin: () => {
    return {
      type: actions.READ_RECENT_SUBMITTALS_BEGIN,
    };
  },

  recentSubmittalsSuccess: data => {
    return { type: actions.READ_RECENT_SUBMITTALS_SUCCESS, data };
  },
};

export default actions;
