function getTabsMetaData() {
  const tabs = [
    { tabName: 'Overview', key: 1 },
    { tabName: 'TextUs', key: 2 },
    { tabName: 'VOIP report', key: 3 },
    { tabName: 'Calendar', key: 4 },
    { tabName: 'Placements', key: 5 },
  ];

  return tabs;
}

export default getTabsMetaData;
